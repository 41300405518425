<template>
  <Login v-if="isLoggedIn == false" v-on:onLogin="onLogin" />
  <Main v-if="isLoggedIn == true" v-bind:isAdmin="isAdmin" v-on:onLogout="onLogout" />
</template>

<script>
import Login from '@/Login.vue';
import Main from '@/Main.vue';
import router from './router';
import Db from '@/db';

export default {
  name: 'App',
  components: {
    Login,
    Main,
  },

  data() {
    return {
      isLoggedIn: false,
      userInfo: null,
    };
  },

  async mounted() {
    this.userInfo = await Db.loadData(this.$store.getters.userDbFilename, 'users');
  },

  methods: {
    async onLogin(goHome) {
      this.userInfo = await Db.loadData(this.$store.getters.userDbFilename, 'users');
      if (!this.userInfo) return;
      const currentUser = this.userInfo.find(({ uid }) => uid == this.$store.getters.loginStatus.user.uid);
      this.isAdmin = (currentUser.authority == 'admin');
      this.$store.dispatch('setDbDataFilename', currentUser.database);
      this.isLoggedIn = true;
      if (goHome) router.push('/home');
    },

    onLogout() {
      this.isLoggedIn = false;
      router.push('/');
    },
  },
}

</script>