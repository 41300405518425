<template>
  <div class="fr_move">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>
    <div class="fr_entryForm fr_scrollableContent">
      <div>
        <div class="fr_sectionTitle">{{ LabelTitle }}</div>
        <ul class="fr_selectList">
          <li class="fr_datapickerWrap">
            <VueDatePicker v-model="date" format="yyyy/MM/dd" :enable-time-picker="false" light />
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBSrcSelected" v-on:change="OnLBSrcChanged">
                <option v-for="src in LBSrc" :value="src">{{ src.name }}</option>
                <option :value="LBSrcSelected" disabled hidden>{{ LabelSelectSrc }}</option>
              </select>
            </div>
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBItemSelected">
                <option v-for="item in LBItem" :value="item">{{ item.name }}</option>
                <option :value="LBItemSelected" disabled hidden>{{ LabelSelectItem }}</option>
              </select>
            </div>
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBDstSelected">
                <option v-for="dst in LBDst" :value="dst">{{ dst.name }}</option>
                <option :value="LBDstSelected" disabled hidden>{{ LabelSelectDst }}</option>
              </select>
            </div>
          </li>
        </ul>

        <ul class="fr_inputboxList">
          <ValueInput v-bind:Values="Array(25).fill(0)" v-on:valueUpdated="valueUpdated" />
        </ul>

        <div class="fr_primaryButton">
          <a v-on:click="execute">{{ CBExecute }}</a>
        </div>

      </div>
      <p><a v-if="enableTest" v-on:click="testInput1(routeName)">{{ test1 }}</a></p>
      <p><a v-if="enableTest" v-on:click="testInput2(routeName)">{{ test2 }}</a></p>
    </div>

    <div class="fr_historyTable">
      <div class="fr_sectionTitle">{{ LabelRecord }}</div>
      <ViewDbData v-bind:update="update" v-bind:enableSelect="false" v-bind:route="routeName" v-on:dataUpdated="dataUpdated" />
    </div>
  </div>
</template>

<script>
import Db from '@/db';
import Util from '@/util';
import { getLocale } from '@/store.js';
import ValueInput from '@/components/ValueInput.vue';
import MoveItems from '@/components/db/MoveItems.js';
import ViewDbData from '@/components/ViewDbData.vue';
import Log from '@/components/db/Log.js';

export default {
  name: 'MoveItems',
  components: {
    ValueInput,
    ViewDbData,
  },
  props: { route: String },

  //----------------------------------------------------------------------
  data() {
    return {
      routeName: null,

      date: new Date(),
      LBSrc: [],
      LBSrcSelected: null,
      LBDst: [],
      LBDstSelected: null,
      LBItem: [],
      LBItemSelected: null,
      TBAmount: Array(25).fill(0),

      FactoryInventory: null,
      ProcessedItems: null,
      Color: null,

      update: false,

      enableTest: process.env.NODE_ENV === 'development',
    };
  },

  //----------------------------------------------------------------------
  computed: {
    LabelTitle() { return this.getLocale(this.route, 'LabelTitle'); },
    LabelSelectSrc() { return this.getLocale(this.route, 'LabelSelectSrc'); },
    LabelSelectDst() { return this.getLocale(this.route, 'LabelSelectDst'); },
    LabelSelectItem() { return this.getLocale(this.route, 'LabelSelectItem'); },
    CBExecute() { return this.getLocale(this.route, 'CBExecute'); },
    Complete() { return this.getLocale(this.route, 'Complete'); },
    LabelRecord() { return this.getLocale(this.route, 'LabelRecord'); },
    test1() { 
      if (this.route == 'moveThread') return 'test:糸移動:生地工場→本社';
      if (this.route == 'moveCloth') return 'test:生地移動:生地工場→染め工場';
      if (this.route == 'moveProduct') return 'test:製品工場→本社';
    },
    test2() {
      if (this.route == 'moveCloth') return 'test:生地移動:染め工場→製品工場';
    }
  },

  //----------------------------------------------------------------------
  created() {
    this.routeName = this.route;
  },
  updated() {
    this.routeName = this.route;
  },

  //----------------------------------------------------------------------
  watch: {
    routeName: async function () {
      this.LBSrc = await Db.loadData(this.$store.getters.dbDataFilename, 'Factory');
      this.LBDst = this.LBSrc;

      // 生産品リスト
      this.ProcessedItems = await Db.loadData(this.$store.getters.dbDataFilename, this.getItemType(this.route).itemType);

      // 色
      this.Color = await Db.loadData(this.$store.getters.dbDataFilename, 'Color');

      // 工場在庫
      this.FactoryInventory = await Db.loadData(this.$store.getters.dbDataFilename, 'FactoryInventory');
      this.FactoryInventory.forEach((item) => {
        if (item.itemType == 'Dyeing') item.itemType = 'Cloth';
      });
    }
  },

  //----------------------------------------------------------------------
  methods: {
    async execute() {
      let itemType = this.getItemType(this.route).itemType;
      if (!itemType) return false;

      // データベース書き込みの共通パラメーター
      const dbDataCommon = {
        src: this.LBSrcSelected,
        dst: this.LBDstSelected,
        itemType: itemType,
        item: this.LBItemSelected,
        amount: Util.getTotal(this.TBAmount),
        amountBd: JSON.stringify(Util.getTotalBd(this.TBAmount)),
        date: Util.formatDate(new Date(this.date)),
      };
      const lastId = await Db.getLastId(this.$store.getters.dbDataFilename);
      const recMoveId = lastId + 1;
      const factoryInventoryId = recMoveId + 1;
      const logId = factoryInventoryId + 1;

      // 移動記録に追加
      const responseRecMove = await MoveItems.addRecMove(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
          id: recMoveId,
        });
      if (responseRecMove.result != "ok") {
        alert("fail\n" + responseRecMove.error.errorInfo);
        return false;
      }

      // 工場在庫に追加
      const responseAddFactoryInventory = await MoveItems.addFactoryInventory(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
          id: factoryInventoryId,
        });
      if (responseAddFactoryInventory.result != "ok") {
        alert("fail\n" + responseAddFactoryInventory.error.errorInfo);
        return false;
      }

      // 工場在庫を減少
      const responseReduceFactoryInventory = await MoveItems.reduceFactoryInventory(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
        });
      if (responseReduceFactoryInventory.result != "ok") {
        alert("fail\n" + responseReduceFactoryInventory.error.errorInfo);
        return false;
      }

      // ログを追加
      const responseAddLog = await Log.addLog(
        this.$store.getters.dbDataFilename, {
        id: logId,
        datetime: Date().toString(),
        pid: recMoveId,
        type: this.routeName,
      });
      if (responseAddLog.result != "ok") {
        alert("fail\n" + responseAddLog.error.errorInfo);
        return false;
      }

      // 完了メッセージ表示
      alert(this.getLocale(this.route, 'Complete'));

      this.update = true;
      return true;
    },

    //----------------------------------------------------------------------
    OnLBSrcChanged: async function () {
      // 移動品リストを更新
      const inventory = this.FactoryInventory.filter((item) => {
        if (item.factoryId != this.LBSrcSelected.id) return false;
        if (item.itemType != this.getItemType(this.route).itemType) return false;
        if (item.status == 'Processing') return false;
        if (item.amount == 0) return false;
        return true;
      });
      inventory.forEach((item) => {
        const processedItem = this.ProcessedItems.find(({ id }) => id == item.itemId);
        const colorId = item.colorId ? item.colorId : 0;
        item.name = processedItem.name + ' / ' + this.Color.find(({ id }) => id == colorId).name
          + ' / ' + item.amount + (item.isLen ? 'm' : 'kg');
      });
      this.LBItem = JSON.parse(JSON.stringify(inventory));
    },

    //----------------------------------------------------------------------
    valueUpdated(amount) {
      this.TBAmount = amount;
    },

    dataUpdated() {
      this.update = false;
    },

    //----------------------------------------------------------------------
    getItemType: function (routeName) {
      if (routeName == 'moveThread') {
        return { itemType: 'Thread' };
      }
      else if (routeName == 'moveCloth') {
        return { itemType: 'Cloth' };
      }
      else if (routeName == 'moveProduct') {
        return { itemType: 'Product' };
      }
    },

    getLocale(routeName, label) {
      if (routeName == 'moveThread') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10008);
        if (label == 'LabelSelectSrc') return getLocale(this.$store.state, 10801);
        if (label == 'LabelSelectDst') return getLocale(this.$store.state, 10802);
        if (label == 'LabelSelectItem') return getLocale(this.$store.state, 10803);
        if (label == 'CBExecute') return getLocale(this.$store.state, 10807);
        if (label == 'Complete') return getLocale(this.$store.state, 10808);
        if (label == 'LabelRecord') return getLocale(this.$store.state, 10217);
      }
      else if (routeName == 'moveCloth') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10009);
        if (label == 'LabelSelectSrc') return getLocale(this.$store.state, 10901);
        if (label == 'LabelSelectDst') return getLocale(this.$store.state, 10902);
        if (label == 'LabelSelectItem') return getLocale(this.$store.state, 10903);
        if (label == 'CBExecute') return getLocale(this.$store.state, 10907);
        if (label == 'Complete') return getLocale(this.$store.state, 10908);
        if (label == 'LabelRecord') return getLocale(this.$store.state, 10217);
      }
      else if (routeName == 'moveProduct') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10010);
        if (label == 'LabelSelectSrc') return getLocale(this.$store.state, 11001);
        if (label == 'LabelSelectDst') return getLocale(this.$store.state, 11002);
        if (label == 'LabelSelectItem') return getLocale(this.$store.state, 11003);
        if (label == 'CBExecute') return getLocale(this.$store.state, 11007);
        if (label == 'Complete') return getLocale(this.$store.state, 11008);
        if (label == 'LabelRecord') return getLocale(this.$store.state, 10217);
      }
    },

    //----------------------------------------------------------------------
    async testInput1(routeName) {
      this.date = Util.formatDate(new Date());

      if (routeName == 'moveThread') {
        this.LBSrcSelected = this.LBSrc.find((item) => { return item.id == 3122; });
        await this.OnLBSrcChanged();
        this.LBDstSelected = this.LBDst.find((item) => { return item.id == 0; });
      }
      else if (routeName == 'moveCloth') {
        this.LBSrcSelected = this.LBSrc.find((item) => { return item.id == 3122; });
        await this.OnLBSrcChanged();
        this.LBDstSelected = this.LBDst.find((item) => { return item.id == 3219; });
      }
      else if (routeName == 'moveProduct') {
        this.LBSrcSelected = this.LBSrc.find((item) => { return item.id == 3214; });
        await this.OnLBSrcChanged();
        this.LBDstSelected = this.LBDst.find((item) => { return item.id == 0; });
      }

      this.LBItemSelected = this.LBItem[0];

      this.TBAmount = Array(25).fill(0);
      this.TBAmount[0] = 1500;
      this.TBAmount[1] = 1500;

      await this.execute();
    },

    //----------------------------------------------------------------------
    async testInput2(routeName) {
      this.date = Util.formatDate(new Date());

      if (routeName == 'moveCloth') {
        this.LBSrcSelected = this.LBSrc.find((item) => { return item.id == 3219; });
        await this.OnLBSrcChanged();
        this.LBDstSelected = this.LBDst.find((item) => { return item.id == 3214; });
      }

      this.LBItemSelected = this.LBItem[0];

      this.TBAmount = Array(25).fill(0);
      this.TBAmount[0] = 1500;
      this.TBAmount[1] = 1500;

      await this.execute();
    },    
  },
}

</script>
