<template>
  <div class="fr_procure">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>
    <div class="fr_entryForm fr_scrollableContent">
      <div>
        <div class="fr_sectionTitle">{{ LabelForm }}</div>
        <ul class="fr_selectList">
          <li class="fr_datapickerWrap">
            <VueDatePicker v-model="date" format="yyyy/MM/dd" :enable-time-picker="false" light />
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBItemSelected">
                <option v-for="item in LBItem" :value="item">{{ item.name }}</option>
                <option :value="LBItemSelected" disabled hidden>{{ LabelSelectItem }}</option>
              </select>
            </div>
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBSupplierSelected">
                <option v-for="supplier in LBSupplier" :value="supplier">{{ supplier.name }}</option>
                <option :value="LBSupplierSelected" disabled hidden>{{ LabelSelectSupplier }}</option>
              </select>
            </div>
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBDeliverySelected">
                <option v-for="delivery in LBDelivery" :value="delivery">{{ delivery.name }}</option>
                <option :value="LBDeliverySelected" disabled hidden>{{ LabelSelectDelivery }}</option>
              </select>
            </div>
          </li>
          <li>
            <div>
              <input type="number" v-model="TBPricePerUnit" :placeholder="LabelFillInPricePerUnit">
            </div>
          </li>
        </ul>

        <div class="fr_radioList">
          <span>{{ LabelDetails }}</span>
          <UnitInput v-on:unitChanged="unitChanged" />
        </div>

        <ul class="fr_inputboxList">
          <ValueInput v-bind:Values="Array(25).fill(0)" v-on:valueUpdated="valueUpdated" />
        </ul>
        <div class="fr_primaryButton">
          <a v-on:click="add">{{ CBAdd }}</a>
        </div>
      </div>

      <div>
        <div class="fr_sectionTitle">{{ LabelInputed }}</div>
        <ProcureTable v-bind:ProcureList="ProcureList" v-bind:route="route" />
        <div class="fr_primaryButton">
          <a v-on:click="execute">{{ CBExecute }}</a>
        </div>
      </div>
      <a v-if="enableTest" v-on:click="testInput(routeName)">test</a>
    </div>
  </div>

  <div class="fr_historyTable">
    <div class="fr_sectionTitle">{{ LabelRecord }}</div>
    <ViewDbData v-bind:update="update" v-bind:enableSelect="false" v-bind:route="routeName" v-on:dataUpdated="dataUpdated" />
  </div>
</template>

<script>
import Db from '@/db';
import Util from '@/util';
import { getLocale } from '@/store.js';
import ValueInput from '@/components/ValueInput.vue';
import UnitInput from '@/components/UnitInput.vue';
import ProcureItems from '@/components/db/ProcureItems.js';
import ProcureTable from '@/components/ProcureTable.vue';
import ViewDbData from '@/components/ViewDbData.vue';

export default {
  name: 'Procure',
  components: {
    ValueInput,
    UnitInput,
    ProcureTable,
    ViewDbData,
  },
  props: { route: String },
  emits: ['onLogout'],

  //----------------------------------------------------------------------
  data() {
    return {
      routeName: null,

      date: new Date(),
      LBItem: [],
      LBItemSelected: {id: null, name: null},
      LBSupplier: [],
      LBSupplierSelected: {id: null, name: null},
      LBDelivery: [],
      LBDeliverySelected: {id: null, name: null},
      TBPricePerUnit: null,
      TBAmount: Array(25).fill(0),
      LabelUnit: "m",
      unit: "length",

      ProcureList: [],

      update: false,
      enableTest: process.env.NODE_ENV === 'development',
    };
  },

  //----------------------------------------------------------------------
  computed: {
    LabelTitle() { return this.getLocale(this.route, 'LabelTitle'); },
    LabelForm() { return this.getLocale(this.route, 'LabelForm'); },
    LabelDetails() { return this.getLocale(this.route, 'LabelDetails'); },
    LabelInputed() { return this.getLocale(this.route, 'LabelInputed'); },
    LabelSelectItem() { return this.getLocale(this.route, 'LabelSelectItem'); },
    LabelSelectSupplier() { return this.getLocale(this.route, 'LabelSelectSupplier'); },
    LabelSelectDelivery() { return this.getLocale(this.route, 'LabelSelectDelivery'); },
    LabelFillInPricePerUnit() { return this.getLocale(this.route, 'LabelFillInPricePerUnit'); },
    LabelFillInLengthWeightProcure() { return this.getLocale(this.route, 'LabelFillInLengthWeightProcure'); },
    LabelLength() { return this.getLocale(this.route, 'LabelLength'); },
    LabelWeight() { return this.getLocale(this.route, 'LabelWeight'); },
    LabelRecord() { return this.getLocale(this.route, 'LabelRecord'); },
    CBAdd() { return this.getLocale(this.route, 'CBAdd'); },
    CBExecute() { return this.getLocale(this.route, 'CBExecute'); },
    Complete() { return this.getLocale(this.route, 'Complete'); },
  },

  //----------------------------------------------------------------------
  created() {
    this.routeName = this.route;
  },
  updated() {
    this.routeName = this.route;
  },

  //----------------------------------------------------------------------
  watch: {
    routeName: async function () {
      this.LBItem = await Db.loadData(this.$store.getters.dbDataFilename, this.getItemType(this.route).itemType);
      this.LBSupplier = await Db.loadData(this.$store.getters.dbDataFilename, this.getItemType(this.route).supplier);
      this.LBDelivery = await Db.loadData(this.$store.getters.dbDataFilename, 'Factory');
      this.LBItemSelected = null;
      this.LBSupplierSelected = null;
      this.LBDeliverySelected = null;
      this.TBPricePerUnit = null;
      this.TBAmount = Array(25).fill(0);
      this.ProcureList = [];
    }
  },

  //----------------------------------------------------------------------
  methods: {
    async execute() {
      await ProcureItems.executeProcure(this.ProcureList);

      // 完了メッセージ表示
      alert(this.Complete);

      this.update = true;
      this.ProcureList = [];
    },

    //----------------------------------------------------------------------
    add() {
      this.ProcureList.push({
        itemType: this.getItemType(this.route).itemType,
        supplier: this.getItemType(this.route).supplier,
        supplierId: this.LBSupplierSelected.id,
        supplierName: this.LBSupplierSelected.name,
        itemId: this.LBItemSelected.id,
        itemName: this.LBItemSelected.name,
        deliveryId: this.LBDeliverySelected.id,
        deliveryName: this.LBDeliverySelected.name,
        pricePerUnit: Number(this.TBPricePerUnit),
        isLen: (this.unit == "length"),
        unitString: Util.getUnitString(this.unit),
        amount: this.getAmount(this.TBAmount),
        amountBd: this.TBAmount,
        amountBdDiv: this.getDiv(this.TBAmount),
        memo: '',
        date: Util.formatDate(new Date(this.date)),
        dbDataFilename: this.$store.getters.dbDataFilename,
      });
      this.ProcureList = JSON.parse(JSON.stringify(this.ProcureList));
    },

    getAmount() {
      let total = 0;
      this.TBAmount.forEach((r) => { total += Number(r); });
      return total;
    },

    getDiv(amountBd) {
      let div = [];
      for (let i = 0; i < 5; i++) {
        let p = [];
        for (let j = 0; j < 5; j++) {
          p.push(Number(amountBd[i * 5 + j]));
        }
        div.push(p);
      }
      return div;
    },

    //----------------------------------------------------------------------
    valueUpdated(amount) {
      this.TBAmount = amount;
    },

    unitChanged(unit) {
      this.unit = unit;
    },

    dataUpdated() {
      this.update = false;
    },

    getCurrentAccoutPayable(supplier) {
      return this.LBSupplier.find(({ id }) => id == supplier).amount;
    },

    //----------------------------------------------------------------------
    getItemType: function (routeName) {
      if (routeName == 'procureThread') {
        return { itemType: 'Thread', supplier: 'SupplierThread' };
      }
      else if (routeName == 'procureCloth') {
        return { itemType: 'Cloth', supplier: 'SupplierCloth' };
      }
    },

    getLocale(routeName, label) {
      if (routeName == 'procureThread') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10002);
        if (label == 'LabelForm') return getLocale(this.$store.state, 10213);
        if (label == 'LabelDetails') return getLocale(this.$store.state, 10214);
        if (label == 'LabelInputed') return getLocale(this.$store.state, 10215);
        if (label == 'LabelSelectItem') return getLocale(this.$store.state, 10201);
        if (label == 'LabelSelectSupplier') return getLocale(this.$store.state, 10202);
        if (label == 'LabelSelectDelivery') return getLocale(this.$store.state, 10212);
        if (label == 'LabelFillInPricePerUnit') return getLocale(this.$store.state, 10203);
        if (label == 'LabelFillInLengthWeightProcure') return getLocale(this.$store.state, 10204);
        if (label == 'LabelLength') return getLocale(this.$store.state, 10205);
        if (label == 'LabelWeight') return getLocale(this.$store.state, 10206);
        if (label == 'LabelRecord') return getLocale(this.$store.state, 10217);
        if (label == 'CBAdd') return getLocale(this.$store.state, 10406);
        if (label == 'CBExecute') return getLocale(this.$store.state, 10207);
        if (label == 'Complete') return getLocale(this.$store.state, 10209);
      }
      else if (routeName == 'procureCloth') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10003);
        if (label == 'LabelForm') return getLocale(this.$store.state, 10313);
        if (label == 'LabelDetails') return getLocale(this.$store.state, 10314);
        if (label == 'LabelInputed') return getLocale(this.$store.state, 10315);
        if (label == 'LabelSelectItem') return getLocale(this.$store.state, 10301);
        if (label == 'LabelSelectSupplier') return getLocale(this.$store.state, 10302);
        if (label == 'LabelSelectDelivery') return getLocale(this.$store.state, 10312);
        if (label == 'LabelFillInPricePerUnit') return getLocale(this.$store.state, 10303);
        if (label == 'LabelFillInLengthWeightProcure') return getLocale(this.$store.state, 10304);
        if (label == 'LabelLength') return getLocale(this.$store.state, 10305);
        if (label == 'LabelWeight') return getLocale(this.$store.state, 10306);
        if (label == 'LabelRecord') return getLocale(this.$store.state, 10317);
        if (label == 'CBAdd') return getLocale(this.$store.state, 10406);
        if (label == 'CBExecute') return getLocale(this.$store.state, 10307);
        if (label == 'Complete') return getLocale(this.$store.state, 10309);
      }
    },

    //----------------------------------------------------------------------
    async testInput(routeName) {
      this.date = Util.formatDate(new Date());

      if (routeName == 'procureThread') {
        this.LBDeliverySelected = this.LBDelivery[1];
      }
      else if (routeName == 'procureCloth') {
        this.LBDeliverySelected = this.LBDelivery[8];
      }
      this.TBAmount = Array(25).fill(0);

      this.LBItemSelected = this.LBItem[0];
      this.LBSupplierSelected = this.LBSupplier[0];
      this.TBPricePerUnit = 50;
      this.TBAmount[0] = 1000;
      this.TBAmount[1] = 2000;
      this.add();

      this.LBItemSelected = this.LBItem[1];
      this.LBSupplierSelected = this.LBSupplier[1];
      this.TBPricePerUnit = 60;
      this.TBAmount[0] = 1500;
      this.TBAmount[1] = 2500;
      this.add();

      await this.execute();
    },
  },
}
</script>

<style>
.dp__theme_light {
  --dp-background-color: #fff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #1976d2;
  --dp-primary-disabled-color: #6bacea;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-marker-color: #ff6f60;
  --dp-tooltip-color: #fafafa;
  --dp-disabled-color-text: #8e8e8e;
  --dp-highlight-color: rgb(25 118 210 / 10%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
  --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);

  /*General*/
  --dp-font-family: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  --dp-border-radius: 4px;
  /*Configurable border-radius*/
  --dp-cell-border-radius: 4px;
  /*Specific border radius for the calendar cell*/
  --dp-common-transition: all 0.1s ease-in;
  /*Generic transition applied on buttons and calendar cells*/

  /*Sizing*/
  --dp-button-height: 35px;
  /*Size for buttons in overlays*/
  --dp-month-year-row-height: 35px;
  /*Height of the month-year select row*/
  --dp-month-year-row-button-size: 35px;
  /*Specific height for the next/previous buttons*/
  --dp-button-icon-height: 20px;
  /*Icon sizing in buttons*/
  --dp-cell-size: 35px;
  /*Width and height of calendar cell*/
  --dp-cell-padding: 5px;
  /*Padding in the cell*/
  --dp-common-padding: 10px;
  /*Common padding used*/
  --dp-input-icon-padding: 35px;
  /*Padding on the left side of the input if icon is present*/
  --dp-input-padding: 6px 30px 6px 12px;
  /*Padding in the input*/
  --dp-menu-min-width: 260px;
  /*Adjust the min width of the menu*/
  --dp-action-buttons-padding: 2px 5px;
  /*Adjust padding for the action buttons in action row*/
  --dp-row-margin: 5px 0;
  /*Adjust the spacing between rows in the calendar*/
  --dp-calendar-header-cell-padding: 0.5rem;
  /*Adjust padding in calendar header cells*/
  --dp-two-calendars-spacing: 10px;
  /*Space between multiple calendars*/
  --dp-overlay-col-padding: 3px;
  /*Padding in the overlay column*/
  --dp-time-inc-dec-button-size: 32px;
  /*Sizing for arrow buttons in the time picker*/
  --dp-menu-padding: 6px 8px;
  /*Menu padding*/

  /*Font sizes*/
  --dp-font-size: 2rem;
  /*Default font-size*/
  --dp-preview-font-size: 0.8rem;
  /*Font size of the date preview in the action row*/
  --dp-time-font-size: 0.8rem;
  /*Font size in the time picker*/

  /*Transitions*/
  --dp-animation-duration: 0.1s;
  /*Transition duration*/
  --dp-menu-appear-transition-timing: cubic-bezier(.4, 0, 1, 1);
  /*Timing on menu appear animation*/
  --dp-transition-timing: ease-out;
  /*Timing on slide animations*/
}
</style>