<template>
    <div v-html="htmlContent"></div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        htmlContent: ''
      };
    },
    mounted() {
      fetch('/sample.html')
        .then(response => response.text())
        .then(data => {
          this.htmlContent = data;
        });
    }
  };
  </script>
  