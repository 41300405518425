import { createStore } from 'vuex'
import locale from '@/locale';

const store = createStore({
  state: {
    dbLocaleFile: 'swatchAILocale.db',
    dbDataFilename: '',
    dbUserFilename: 'users.db',
    lang: 'jp',
    locale: null,
    auth: null,
    loginStatus: {
      login: false,
      user: null
    },
  },
  getters: {
    dbDataFilename(state) { return state.dbDataFilename; },
    lang(state) { return state.lang; },
    auth(state) { return state.auth; },
    loginStatus(state) { return state.loginStatus },
    userDbFilename(state) { return state.dbUserFilename; },
  },
  mutations: {
    setLocale(state, payload) {
      state.locale = payload.locale;
    },
    setLang(state, payload) {
      state.lang = payload.lang;
    },
    setDbDataFilename(state, payload) {
      state.dbDataFilename = payload.dbDataFilename;
    },
    setAuth(state, payload) {
      state.auth = payload.auth;
    },
    setLoginStatus(state, payload) {
      state.loginStatus = payload.loginStatus;
    },
  },
  actions: {
    async initLocale({ commit }) {
      commit('setLocale', { locale });
    },
    setLang({ commit }, lang) {
      commit('setLang', { lang });
    },
    setDbDataFilename({ commit }, dbDataFilename) {
      commit('setDbDataFilename', { dbDataFilename });
    },
    setAuth({ commit }, auth) {
      commit('setAuth', { auth });
    },
    setLoginStatus({ commit }, loginStatus) {
      commit('setLoginStatus', { loginStatus });
    }
  },
});

export const getLocale = (state, id) => {
  return (state.locale != null ? state.locale[id][state.lang] : '__');
}

export default store;