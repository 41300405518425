<template>
  <div class="fr_procduction">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>
    <div class="fr_entryForm fr_scrollableContent">
      <div>
        <div class="fr_sectionTitle">{{ LabelForm }}</div>
        <ul class="fr_selectList">
          <li class="fr_datapickerWrap">
            <VueDatePicker
              v-model="date"
              format="yyyy/MM/dd"
              :enable-time-picker="false"
              light
            />
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select
                v-model="LBFactorySelected"
                v-on:change="OnLBFactoryChanged"
                return-object
              >
                <option
                  v-for="factory in LBFactory"
                  :value="factory"
                  :key="factory.id"
                >
                  {{ factory.name }}
                </option>
                <option :value="LBFactorySelected" disabled hidden>
                  {{ LabelSelectFactory }}
                </option>
              </select>
            </div>
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select
                v-model="LBItemSelected"
                v-on:change="OnLBItemChanged"
                return-object
              >
                <option v-for="item in LBItem" :value="item">
                  {{ item.name }}
                </option>
                <option :value="LBItemSelected" disabled hidden>
                  {{ LabelSelectItem }}
                </option>
              </select>
            </div>
          </li>
          <li v-if="routeName == 'startProductionProduct'">
            <div class="fr_selecterWrap">
              <select v-model="LBColorSelected" return-object>
                <option v-for="color in LBColor" :value="color">
                  {{ color.name }}
                </option>
                <option :value="LBColorSelected" disabled hidden>
                  {{ LabelSelectColor }}
                </option>
              </select>
            </div>
          </li>
        </ul>

        <p>{{ LabelSelectAndAdd }}</p>
        <ul class="fr_selectList">
          <li>
            <div class="fr_selecterWrap">
              <select
                id="SelectInventory"
                v-model="LBInventorySelected"
                return-object
              >
                <option v-for="inventory in LBInventory" :value="inventory">
                  {{ inventory.name }}
                </option>
                <option :value="LBInventorySelected" disabled hidden>
                  {{ LabelInventoryList }}
                </option>
              </select>
            </div>
          </li>
          <li>
            <div>
              <input
                type="number"
                :placeholder="LabelRatio"
                v-model="TBRatioPercent"
              />
            </div>
          </li>
          <li>
            <div>
              <input
                type="number"
                :placeholder="LabelLoss"
                v-model="TBLossPercent"
              />
            </div>
          </li>
        </ul>
        <div class="fr_primaryButton">
          <a
            v-on:click="
              addMaterial(LBInventorySelected, TBRatioPercent, TBLossPercent)
            "
            >{{ CBAdd }}</a
          >
        </div>

        <p>{{ LabelMaterialList }}</p>
        <ul>
          <li v-for="(item, index) in LBMaterial" :value="item.id" :key="index">
            {{ item.name }}, {{ item.ratioPercent }}%, {{ item.lossPercent }}%
            <button v-on:click="removeMaterial(index)">
              <img src="../assets/img/icons/icon-trash.svg" alt="" />
            </button>
          </li>
        </ul>

        <div class="fr_radioList">
          <span>{{ LabelFillInExpect }}</span>
          <div class="">
            <UnitInput v-on:unitChanged="unitChanged" />
          </div>
        </div>

        <ul class="fr_inputboxList">
          <ValueInput
            v-bind:Values="Array(25).fill(0)"
            v-on:valueUpdated="valueUpdated"
          />
        </ul>

        <div class="fr_inputSingleSmall">
          <input
            type="number"
            :placeholder="LabelFillInCost"
            v-model="TBCost"
          />
        </div>

        <div class="fr_primaryButton">
          <a v-on:click="execute">{{ CBExecute }}</a>
        </div>
      </div>
      <p>
        <a v-if="enableTest" v-on:click="testInput1(routeName)">test（最初）</a>
      </p>
      <p>
        <a v-if="enableTest" v-on:click="testInput2(routeName)"
          >test（２回目）</a
        >
      </p>
    </div>

    <div class="fr_historyTable">
      <div class="fr_sectionTitle">{{ LabelRecord }}</div>
      <ViewDbData
        v-bind:update="update"
        v-bind:enableSelect="false"
        v-bind:route="routeName"
        v-on:dataUpdated="dataUpdated"
      />
    </div>
  </div>
</template>

<script>
import Db from "@/db";
import Util from "@/util";
import { getLocale } from "@/store.js";
import ValueInput from "@/components/ValueInput.vue";
import UnitInput from "@/components/UnitInput.vue";
import ProcessItems from "@/components/db/ProcessItems.js";
import ViewDbData from "@/components/ViewDbData.vue";
import Log from "@/components/db/Log.js";

export default {
  name: "Process",
  components: {
    ValueInput,
    UnitInput,
    ViewDbData,
  },
  props: { route: String },
  emits: ["onLogout"],

  //----------------------------------------------------------------------
  data() {
    return {
      routeName: null,

      date: new Date(),
      LBFactory: [],
      LBFactorySelected: null,
      LBItem: [],
      LBItemSelected: null,
      LBColor: [],
      LBColorSelected: null,
      LBInventory: [],
      LBInventorySelected: null,
      LBMaterial: [],
      TBAmount: Array(25).fill(0),
      TBCost: null,
      unit: "length",
      TBRatioPercent: null,
      TBLossPercent: null,

      FactoryInventory: null,
      Color: null,
      Material: null,

      update: false,

      enableTest: process.env.NODE_ENV === "development",
    };
  },

  //----------------------------------------------------------------------
  computed: {
    LabelTitle() {
      return this.getLocale(this.route, "LabelTitle");
    },
    LabelForm() {
      return this.getLocale(this.route, "LabelForm");
    },
    LabelSelectFactory() {
      return this.getLocale(this.route, "LabelSelectFactory");
    },
    LabelSelectItem() {
      return this.getLocale(this.route, "LabelSelectItem");
    },
    LabelSelectColor() {
      return this.getLocale(this.route, "LabelSelectColor");
    },
    LabelSelectAndAdd() {
      return this.getLocale(this.route, "LabelSelectAndAdd");
    },
    LabelInventoryList() {
      return this.getLocale(this.route, "LabelInventoryList");
    },
    CBAdd() {
      return this.getLocale(this.route, "CBAdd");
    },
    LabelMaterialList() {
      return this.getLocale(this.route, "LabelMaterialList");
    },
    LabelMaterial() {
      return this.getLocale(this.route, "LabelMaterial");
    },
    LabelRatio() {
      return this.getLocale(this.route, "LabelRatio");
    },
    LabelLoss() {
      return this.getLocale(this.route, "LabelLoss");
    },
    CBRemove() {
      return this.getLocale(this.route, "CBRemove");
    },
    LabelFillInExpect() {
      return this.getLocale(this.route, "LabelFillInExpect");
    },
    LabelFillInCost() {
      return this.getLocale(this.route, "LabelFillInCost");
    },
    LabelRecord() {
      return this.getLocale(this.route, "LabelRecord");
    },
    CBExecute() {
      return this.getLocale(this.route, "CBExecute");
    },
  },

  //----------------------------------------------------------------------
  created() {
    this.routeName = this.route;
  },
  updated() {
    this.routeName = this.route;
  },

  //----------------------------------------------------------------------
  watch: {
    routeName: async function () {
      // 現在の処理に応じた工場種類の工場を工場選択リストに入れる
      const tableFactory = await Db.loadData(
        this.$store.getters.dbDataFilename,
        "Factory"
      );
      const factoryType = this.getItemType(this.route).itemType;
      this.LBFactory = tableFactory.filter((item) => {
        return item.type == factoryType;
      });
      this.LBFactorySelected = null;

      // 生産品リスト
      this.LBItem = await Db.loadData(
        this.$store.getters.dbDataFilename,
        this.getItemType(this.route).itemType
      );
      this.LBItemSelected = null;

      // 材料リスト
      this.Material = await Db.loadData(
        this.$store.getters.dbDataFilename,
        this.getItemType(this.route).materialType
      );
      this.LBMaterial = [];

      // 工場在庫
      this.FactoryInventory = await Db.loadData(
        this.$store.getters.dbDataFilename,
        "FactoryInventory"
      );

      // 色
      this.Color = await Db.loadData(
        this.$store.getters.dbDataFilename,
        "Color"
      );
      if (this.routeName == "startProductionCloth")
        this.LBColorSelected = { id: 0 };
      else this.LBColorSelected = null;
    },
  },

  //----------------------------------------------------------------------
  methods: {
    async execute() {
      let itemType = this.getItemType(this.route).itemType;
      if (!itemType) return false;

      // データベース書き込みの共通パラメーター
      const dbDataCommon = {
        factoryId: this.LBFactorySelected.id,
        itemType: itemType,
        itemId: this.LBItemSelected.id,
        colorId: this.LBColorSelected.id,
        isLen: this.unit == "length",
        material: this.LBMaterial,
        amount: Util.getTotal(this.TBAmount),
        amountBd: JSON.stringify({ pre: Util.getTotalBd(this.TBAmount) }),
        cost: this.TBCost,
        status: "Processing",
        date: Util.formatDate(new Date(this.date)),
      };
      const lastId = await Db.getLastId(this.$store.getters.dbDataFilename);
      const recProcessId = lastId + 1;
      const recDetailId = recProcessId + 1;
      const factoryInventoryId = recDetailId + this.LBMaterial.length;
      const logId = factoryInventoryId + 1;

      // 加工記録に追加
      const responseAddRecProcess = await ProcessItems.addRecProcess(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
          id: recProcessId,
        }
      );
      if (responseAddRecProcess.result != "ok") {
        alert("fail\n" + responseAddRecProcess.error.errorInfo);
        return false;
      }

      // 加工明細に追加
      const responseAddRecDetails = await ProcessItems.addRecDetails(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
          id: recDetailId,
          processId: recProcessId,
        }
      );
      if (responseAddRecDetails.result != "ok") {
        alert("fail\n" + responseAddRecDetails.error.errorInfo);
        return false;
      }

      // 工場在庫に追加
      const responseAddFactoryInventory =
        await ProcessItems.addFactoryInventory(
          this.$store.getters.dbDataFilename,
          {
            ...dbDataCommon,
            id: factoryInventoryId,
            recordId: recProcessId,
          }
        );
      if (responseAddFactoryInventory.result != "ok") {
        alert("fail\n" + responseAddFactoryInventory.error.errorInfo);
        return false;
      }

      // 工場在庫の材料を減少
      const responseReduceMaterial = await ProcessItems.reduceMaterial(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
        }
      );
      if (responseReduceMaterial.result != "ok") {
        alert("fail\n" + responseReduceMaterial.error.errorInfo);
        return false;
      }

      // 材料を記録
      const responseUpdateMaterial =
        await ProcessItems.updateMaterialOfClothOrProduct(
          this.$store.getters.dbDataFilename,
          itemType,
          dbDataCommon
        );
      if (responseUpdateMaterial.result != "ok") {
        alert("fail\n" + responseUpdateMaterial.error.errorInfo);
        return false;
      }

      // ログを追加
      const responseAddLog = await Log.addLog(
        this.$store.getters.dbDataFilename,
        {
          id: logId,
          datetime: Date().toString(),
          pid: recProcessId,
          type: this.routeName,
        }
      );
      if (responseAddLog.result != "ok") {
        alert("fail\n" + responseAddLog.error.errorInfo);
        return false;
      }

      // 完了メッセージ表示
      alert(this.getLocale(this.route, "Complete"));

      this.update = true;
      return true;
    },

    //----------------------------------------------------------------------
    OnLBFactoryChanged() {
      const factoryType = this.getItemType(this.route).itemType;
      const inventory = this.FactoryInventory.filter((item) => {
        if (item.factoryId != this.LBFactorySelected.id) return false;
        if (factoryType == "Cloth" && item.itemType == "Thread") return true;
        if (factoryType == "Product" && item.itemType == "Cloth") return true;
        return false;
      });
      inventory.forEach((item) => {
        const mat = this.Material.find(({ id }) => id == item.itemId);
        const colorId = item.colorId ? item.colorId : 0;
        item.name = mat.name;
        if (factoryType == "Product")
          item.name += " " + this.Color.find(({ id }) => id == colorId).name;
        item.name += " " + item.amount;
        item.name += item.isLen ? "m" : "kg";
      });
      this.LBInventory = JSON.parse(JSON.stringify(inventory));
    },

    //----------------------------------------------------------------------
    OnLBItemChanged() {
      const factoryType = this.getItemType(this.route).itemType;
      this.LBMaterial =
        this.LBItemSelected.material == null ||
        this.LBItemSelected.material == ""
          ? []
          : JSON.parse(decodeURIComponent(this.LBItemSelected.material));
      this.LBMaterial.forEach((mat) => {
        const item = this.FactoryInventory.find(
          ({ id }) => id == mat.inventoryId
        );
        mat.name = this.Material.find(({ id }) => id == item.itemId).name;

        const colorId = item.colorId ? item.colorId : 0;
        if (factoryType == "Product")
          mat.name += " " + this.Color.find(({ id }) => id == colorId).name;
        mat.name += " " + item.amount;
        mat.name += item.isLen ? "m" : "kg";
      });

      if (this.getItemType(this.route).itemType == "Cloth") return;
      const itemColor = JSON.parse(this.LBItemSelected.colorId);
      const color = this.Color.filter((item) => {
        if (itemColor.includes(item.id)) return true;
        else return false;
      });
      this.LBColor = color;
    },

    //----------------------------------------------------------------------
    addMaterial(item, ratioPercent, lossPercent) {
      this.LBMaterial.push({
        inventoryId: item.id,
        ratioPercent,
        lossPercent,
        materialId: item.itemId,
        name: this.Material.find(({ id }) => id == item.itemId).name,
      });
    },

    removeMaterial(index) {
      this.LBMaterial.splice(index);
    },

    //----------------------------------------------------------------------
    valueUpdated(amount) {
      this.TBAmount = amount;
    },

    unitChanged(unit) {
      this.unit = unit;
    },

    dataUpdated() {
      this.update = false;
    },

    //----------------------------------------------------------------------
    getItemType(routeName) {
      if (routeName == "startProductionCloth") {
        return { itemType: "Cloth", materialType: "Thread" };
      } else if (routeName == "startProductionProduct") {
        return { itemType: "Product", materialType: "Cloth" };
      }
    },

    getLocale(routeName, label) {
      if (routeName == "startProductionCloth") {
        if (label == "LabelTitle") return getLocale(this.$store.state, 10004);
        if (label == "LabelForm") return getLocale(this.$store.state, 10419);
        if (label == "LabelSelectFactory")
          return getLocale(this.$store.state, 10401);
        if (label == "LabelSelectItem")
          return getLocale(this.$store.state, 10408);
        if (label == "LabelSelectAndAdd")
          return getLocale(this.$store.state, 10402);
        if (label == "LabelInventoryList")
          return getLocale(this.$store.state, 10403);
        if (label == "CBAdd") return getLocale(this.$store.state, 10406);
        if (label == "LabelMaterialList")
          return getLocale(this.$store.state, 10407);
        if (label == "LabelMaterial")
          return getLocale(this.$store.state, 10418);
        if (label == "LabelRatio") return getLocale(this.$store.state, 10404);
        if (label == "LabelLoss") return getLocale(this.$store.state, 10405);
        if (label == "CBRemove") return getLocale(this.$store.state, 10416);
        if (label == "LabelFillInExpect")
          return getLocale(this.$store.state, 10409);
        if (label == "LabelFillInCost")
          return getLocale(this.$store.state, 10412);
        if (label == "LabelRecord") return getLocale(this.$store.state, 10420);
        if (label == "CBExecute") return getLocale(this.$store.state, 10413);
        if (label == "Complete") return getLocale(this.$store.state, 10414);
      } else if (this.route == "startProductionProduct") {
        if (label == "LabelTitle") return getLocale(this.$store.state, 10005);
        if (label == "LabelForm") return getLocale(this.$store.state, 10519);
        if (label == "LabelSelectFactory")
          return getLocale(this.$store.state, 10501);
        if (label == "LabelSelectItem")
          return getLocale(this.$store.state, 10508);
        if (label == "LabelSelectColor")
          return getLocale(this.$store.state, 11603);
        if (label == "LabelSelectAndAdd")
          return getLocale(this.$store.state, 10502);
        if (label == "LabelInventoryList")
          return getLocale(this.$store.state, 10503);
        if (label == "CBAdd") return getLocale(this.$store.state, 10506);
        if (label == "LabelMaterialList")
          return getLocale(this.$store.state, 10507);
        if (label == "LabelMaterial")
          return getLocale(this.$store.state, 10518);
        if (label == "LabelRatio") return getLocale(this.$store.state, 10504);
        if (label == "LabelLoss") return getLocale(this.$store.state, 10505);
        if (label == "CBRemove") return getLocale(this.$store.state, 10516);
        if (label == "LabelFillInExpect")
          return getLocale(this.$store.state, 10509);
        if (label == "LabelFillInCost")
          return getLocale(this.$store.state, 10512);
        if (label == "LabelRecord") return getLocale(this.$store.state, 10520);
        if (label == "CBExecute") return getLocale(this.$store.state, 10513);
        if (label == "Complete") return getLocale(this.$store.state, 10514);
      }
    },

    //----------------------------------------------------------------------
    async testInput1(routeName) {
      this.date = Util.formatDate(new Date());

      if (routeName == "startProductionCloth") {
        this.LBFactorySelected = this.LBFactory.find((item) => {
          return item.id == 3122;
        });
        this.OnLBFactoryChanged();
        this.LBItemSelected = this.LBItem[0];
        this.OnLBItemChanged();
        this.addMaterial(this.LBInventory[0], 60, 1);
        this.addMaterial(this.LBInventory[1], 40, 2);
      } else if (routeName == "startProductionProduct") {
        this.LBFactorySelected = this.LBFactory.find((item) => {
          return item.id == 3214;
        });
        this.OnLBFactoryChanged();
        this.LBItemSelected = this.LBItem[0];
        this.OnLBItemChanged();
        this.addMaterial(this.LBInventory[0], 100, 1);
      }

      this.TBAmount = Array(25).fill(0);
      this.TBAmount[0] = 1500;
      this.TBAmount[1] = 1500;
      this.TBCost = 2000;

      if (routeName == "startProductionProduct") {
        this.LBColorSelected = this.LBColor[0];
      }

      await this.execute();
    },

    //----------------------------------------------------------------------
    async testInput2(routeName) {
      this.date = Util.formatDate(new Date());

      if (routeName == "startProductionCloth") {
        this.LBFactorySelected = this.LBFactory.find((item) => {
          return item.id == 3122;
        });
        this.OnLBFactoryChanged();
      } else if (routeName == "startProductionProduct") {
        this.LBFactorySelected = this.LBFactory.find((item) => {
          return item.id == 3214;
        });
        this.OnLBFactoryChanged();
      }

      this.LBItemSelected = this.LBItem[0];
      this.OnLBItemChanged();
      this.TBAmount = Array(25).fill(0);
      this.TBAmount[0] = 1500;
      this.TBAmount[1] = 1500;
      this.TBCost = 500;

      if (routeName == "startProductionProduct") {
        this.LBColorSelected = this.LBColor[0];
      }

      await this.execute();
    },
  },
};
</script>
