<template>
  <div class="fr_pageTitle">{{ LabelSampleTitle }}</div>
  <div class="fr_entryForm fr_scrollableContent">
    <div class="fr_inputSingle">
      <input type="text" v-model="textInputbox" :placeholder="LabelSampleInput">
    </div>

    <div class="fr_primaryButton">
      <a v-on:click="setText">{{ LabelSampleSetText }}</a>
    </div>

    {{ LabelSampleInputedText }}: {{ inputedText }}
  </div>
</template>

<script>
import { getLocale } from '@/store.js';

export default {
  name: 'Sample',
  data() {
    return {
      LabelSampleTitle: getLocale(this.$store.state, 100),
      LabelSampleInput: getLocale(this.$store.state, 101),
      LabelSampleSetText: getLocale(this.$store.state, 102),
      LabelSampleInputedText: getLocale(this.$store.state, 103),
      textInputbox: '',
      inputedText: '',
    };
  },

  methods: {
    setText() {
      this.inputedText = this.textInputbox;
    },
  },
};

</script>