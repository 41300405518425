<template>
  <div class="fr_payment">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>
    <div class="fr_entryForm fr_scrollableContent">
      <div>
        <div class="fr_sectionTitle">{{ LabelTitle }}</div>
        <ul class="fr_selectList">
          <li class="fr_datapickerWrap">
            <VueDatePicker v-model="date" format="yyyy/MM/dd" :enable-time-picker="false" light />
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBDealerSelected">
                <option v-for="dealer in LBDealer" :value="dealer">{{ dealer.name }} </option>
                <option :value="LBDealerSelected" disabled hidden>{{ LabelSelectDealer }}</option>
              </select>
            </div>
          </li>
          <li>
            <div>
              <input type="number" v-model="TBAmount" :placeholder="LabelAmount">
            </div>
          </li>
        </ul>
        <div class="fr_primaryButton">
          <a v-on:click="execute">{{ CBExecute }}</a>
        </div>
      </div>
      <button v-if="enableTest" v-on:click="testInput(routeName)">test</button>
    </div>
    <div class="fr_historyTable">
      <div class="fr_sectionTitle">{{ LabelRecord }}</div>
      <ViewDbData v-bind:update="update" v-bind:enableSelect="false" v-bind:route="routeName" v-on:dataUpdated="dataUpdated" />
    </div>
  </div>
</template>

<script>
import Db from '@/db';
import Util from '@/util';
import { getLocale } from '@/store.js';
import PaymentCollect from '@/components/db/PaymentCollect.js';
import ViewDbData from '@/components/ViewDbData.vue';
import Log from '@/components/db/Log.js';

export default {
  name: 'PaymentCollectItems',
  components: {
    ViewDbData,
  },
  props: { route: String },

  //----------------------------------------------------------------------
  data() {
    return {
      routeName: null,

      date: new Date(),
      LBDealer: [],
      LBDealerSelected: null,
      TBAmount: null,

      update: false,

      enableTest: process.env.NODE_ENV === 'development',
    };
  },

  //----------------------------------------------------------------------
  computed: {
    LabelTitle() { return this.getLocale(this.route, 'LabelTitle'); },
    LabelSelectDealer() { return this.getLocale(this.route, 'LabelSelectDealer'); },
    LabelAmount() { return this.getLocale(this.route, 'LabelAmount'); },
    LabelRecord() { return this.getLocale(this.route, 'LabelRecord') },
    CBExecute() { return this.getLocale(this.route, 'CBExecute'); },
    Complete() { return this.getLocale(this.route, 'Complete'); },
  },

  //----------------------------------------------------------------------
  created() {
    this.routeName = this.route;
  },
  updated() {
    this.routeName = this.route;
  },

  //----------------------------------------------------------------------
  watch: {
    routeName: async function () {
      this.loadData();
    }
  },

  //----------------------------------------------------------------------
  methods: {
    async execute() {
      const dealType = this.getDealType(this.route).dealType;
      if (!dealType) return false;

      const dbDataCommon = {
        dealer: this.LBDealerSelected,
        dealType: dealType,
        amount: this.TBAmount,
        date: Util.formatDate(new Date(this.date)),
      };

      const recPaymentCollectId = await Db.getLastId(this.$store.getters.dbDataFilename) + 1;
      const logId = recPaymentCollectId + 1;

      // 買掛金・売掛金を減らす
      const responseReduceAccounts = await PaymentCollect.reduceAccounts(
        this.$store.getters.dbDataFilename,
        this.getDealType(this.route).dealType,
        {
          ...dbDataCommon,
        });
      if (responseReduceAccounts.result != "ok") {
        alert("fail\n" + responseReduceAccounts.error.errorInfo);
        return false;
      }

      // 支払い・回収記録を追加
      const responseAddRecPaymentCollect = await PaymentCollect.addRecPaymentCollect(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
          id: recPaymentCollectId,
          dealType: this.getDealType(this.route).dealType,
        });
      if (responseAddRecPaymentCollect.result != "ok") {
        alert("fail\n" + responseAddRecPaymentCollect.error.errorInfo);
        return false;
      }

      // ログを追加
      const responseAddLog = await Log.addLog(
        this.$store.getters.dbDataFilename, {
        id: logId,
        datetime: Date().toString(),
        pid: recPaymentCollectId,
        type: this.routeName,
      });
      if (responseAddLog.result != "ok") {
        alert("fail\n" + responseAddLog.error.errorInfo);
        return false;
      }

      // 完了メッセージ表示
      alert(this.Complete);

      this.update = true;
      await this.loadData();
      return true;
    },

    //----------------------------------------------------------------------
    async loadData() {
      this.LBDealer = await Db.loadData(this.$store.getters.dbDataFilename, this.getDealType(this.route).dealType);
      this.LBDealer.forEach((item) => {
        item.name += ' / ' + item.amount;
      });
    },

    //----------------------------------------------------------------------
    dataUpdated() {
      this.update = false;
    },

    //----------------------------------------------------------------------
    getDealType: function (routeName) {
      if (routeName == 'paymentThread') {
        return { dealType: 'SupplierThread', itemType: 'Thread' };
      }
      else if (routeName == 'paymentCloth') {
        return { dealType: 'SupplierCloth', itemType: 'Cloth' };
      }
      else if (routeName == 'collectReceivable') {
        return { dealType: 'Customer', itemType: 'Product' };
      }
    },

    getLocale(routeName, label) {
      if (routeName == 'paymentThread') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10011);
        if (label == 'LabelSelectDealer') return getLocale(this.$store.state, 11101);
        if (label == 'LabelAmount') return getLocale(this.$store.state, 11102);
        if (label == 'CBExecute') return getLocale(this.$store.state, 11103);
        if (label == 'Complete') return getLocale(this.$store.state, 11104);
      }
      else if (routeName == 'paymentCloth') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10012);
        if (label == 'LabelSelectDealer') return getLocale(this.$store.state, 11201);
        if (label == 'LabelAmount') return getLocale(this.$store.state, 11202);
        if (label == 'CBExecute') return getLocale(this.$store.state, 11203);
        if (label == 'Complete') return getLocale(this.$store.state, 11204);
      }
      else if (routeName == 'collectReceivable') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10014);
        if (label == 'LabelSelectDealer') return getLocale(this.$store.state, 11401);
        if (label == 'LabelAmount') return getLocale(this.$store.state, 11402);
        if (label == 'CBExecute') return getLocale(this.$store.state, 11403);
        if (label == 'Complete') return getLocale(this.$store.state, 11404);
      }
    },

    //----------------------------------------------------------------------
    async testInput() {
      this.date = Util.formatDate(new Date());

      this.LBDealerSelected = this.LBDealer[0];
      this.TBAmount = 10000;

      await this.execute();
    },
  },
}

</script>
