<template>
  <div class="fr_procure">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>
    <div class="fr_entryForm fr_scrollableContent">
      <div>
        <div class="fr_sectionTitle">{{ LabelTitle }}</div>
        <ul class="fr_selectList">
          <li class="fr_datapickerWrap">
            <VueDatePicker v-model="date" format="yyyy/MM/dd" :enable-time-picker="false" light />
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBFactorySelected" v-on:change="OnLBFactoryChanged">
                <option v-for="factory in LBFactory" :value="factory">{{ factory.name }}</option>
                <option :value="LBFactorySelected" disabled hidden>{{ LabelSelectFactory }}</option>
              </select>
            </div>
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBItemSelected">
                <option v-for="item in LBItem" :value="item">{{ item.name }}</option>
                <option :value="LBItemSelected" disabled hidden>{{ LabelSelectItem }}</option>
              </select>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <div class="fr_sectionTitle">{{ LabelFillInResult }}</div>
        <ul class="fr_inputboxList">
          <ValueInput v-bind:Values="Array(25).fill(0)" v-on:valueUpdated="valueUpdated" />
        </ul>
        <div class="fr_primaryButton">
          <a v-on:click="execute">{{ CBExecute }}</a>
        </div>
      </div>
      <button v-if="enableTest" v-on:click="testInput(routeName)">test</button>
    </div>
  </div>

  <div class="fr_historyTable">
    <div class="fr_sectionTitle">{{ LabelRecord }}</div>
    <ViewDbData v-bind:update="update" v-bind:enableSelect="false" v-bind:route="routeName" v-on:dataUpdated="dataUpdated" />
  </div>
</template>

<script>
import Db from '@/db';
import Util from '@/util';
import { getLocale } from '@/store.js';
import ValueInput from '@/components/ValueInput.vue';
import FinishItems from '@/components/db/FinishItems.js';
import ViewDbData from '@/components/ViewDbData.vue';
import Log from '@/components/db/Log.js';

export default {
  name: 'Finish',
  components: {
    ValueInput,
    ViewDbData,
  },
  props: { route: String },
  emits: ['onLogout'],

  //----------------------------------------------------------------------
  data() {
    return {
      routeName: null,

      date: new Date(),
      LBFactory: [],
      LBFactorySelected: null,
      LBItem: [],
      LBItemSelected: null,
      TBAmount: Array(25).fill(0),

      FactoryInventory: null,
      ProcessedItems: null,
      Color: null,

      update: false,

      enableTest: process.env.NODE_ENV === 'development',
    };
  },

  //----------------------------------------------------------------------
  computed: {
    LabelTitle() { return this.getLocale(this.route, 'LabelTitle') },
    LabelSelectFactory() { return this.getLocale(this.route, 'LabelSelectFactory') },
    LabelSelectItem() { return this.getLocale(this.route, 'LabelSelectItem') },
    LabelFillInResult() { return this.getLocale(this.route, 'LabelFillInResult') },
    LabelRecord() { return this.getLocale(this.route, 'LabelRecord') },
    CBExecute() { return this.getLocale(this.route, 'CBExecute') },
    Complete() { return this.getLocale(this.route, 'Complete') },
  },

  //----------------------------------------------------------------------
  created() {
    this.routeName = this.route;
  },
  updated() {
    this.routeName = this.route;
  },

  //----------------------------------------------------------------------
  watch: {
    routeName: async function () {
      // 現在の処理に応じた工場種類の工場を工場選択リストに入れる
      const tableFactory = await Db.loadData(this.$store.getters.dbDataFilename, 'Factory');
      const factoryType = this.getItemType(this.route).factoryType;
      this.LBFactory = tableFactory.filter((item) => { return item.type == factoryType; });

      // 生産品リスト
      this.ProcessedItems = await Db.loadData(this.$store.getters.dbDataFilename, this.getItemType(this.route).itemType);

      // 色
      this.Color = await Db.loadData(this.$store.getters.dbDataFilename, 'Color');

      // 工場在庫
      this.FactoryInventory = await Db.loadData(this.$store.getters.dbDataFilename, 'FactoryInventory');
    }
  },

  //----------------------------------------------------------------------
  methods: {
    async execute() {
      const itemType = this.getItemType(this.route).itemType;
      if (!itemType) return false;

      // 加工記録を更新
      await FinishItems.updateRecProcess(this.$store.getters.dbDataFilename, {
        processId: this.LBItemSelected.recordId,
        amount: Util.getTotal(this.TBAmount),
        amountBd: Util.getTotalBd(this.TBAmount),
        date: Util.formatDate(new Date(this.date)),
      });

      // 工場在庫を更新
      await FinishItems.updateFactoryInventory(this.$store.getters.dbDataFilename, {
        inventoryId: this.LBItemSelected.id,
        amount: Util.getTotal(this.TBAmount),
        date: Util.formatDate(new Date(this.date)),
      });

      // ログを追加
      const responseAddLog = await Log.addLog(
        this.$store.getters.dbDataFilename, {
        id: await Db.getLastId(this.$store.getters.dbDataFilename) + 1,
        datetime: Date().toString(),
        pid: this.LBItemSelected.recordId,
        type: this.routeName,
      });
      if (responseAddLog.result != "ok") {
        alert("fail\n" + responseAddLog.error.errorInfo);
        return false;
      }

      // 完了メッセージ表示
      alert(this.getLocale(this.route, 'Complete'));

      this.update = true;
      return true;
    },

    //----------------------------------------------------------------------
    OnLBFactoryChanged: async function () {
      // 生産品リストを更新
      const factoryType = this.getItemType(this.route).factoryType;
      const inventory = this.FactoryInventory.filter((item) => {
        if (item.factoryId != this.LBFactorySelected.id) return false;
        if (item.itemType != factoryType) return false;
        if (item.status != 'Processing') return false;
        return true;
      });
      inventory.forEach((item) => {
        const processedItem = this.ProcessedItems.find(({ id }) => id == item.itemId);
        const colorId = item.colorId ? item.colorId : 0;
        item.name = processedItem.name + ' / ' + this.Color.find(({ id }) => id == colorId).name
          + ' / ' + item.amount + (item.isLen ? 'm' : 'kg');
      });
      this.LBItem = JSON.parse(JSON.stringify(inventory));
    },

    //----------------------------------------------------------------------
    valueUpdated(amount) {
      this.TBAmount = amount;
    },

    dataUpdated() {
      this.update = false;
    },

    //----------------------------------------------------------------------
    getItemType(routeName) {
      if (routeName == 'finishProductionCloth') {
        return { itemType: 'Cloth', factoryType: 'Cloth' };
      }
      else if (routeName == 'finishDyeingCloth') {
        return { itemType: 'Cloth', factoryType: 'Dyeing' };
      }
      else if (routeName == 'finishProductionProduct') {
        return { itemType: 'Product', factoryType: 'Product' };
      }
    },

    getLocale(routeName, label) {
      if (routeName == 'finishProductionCloth') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10006);
        if (label == 'LabelSelectFactory') return getLocale(this.$store.state, 10601);
        if (label == 'LabelSelectItem') return getLocale(this.$store.state, 10602);
        if (label == 'LabelFillInResult') return getLocale(this.$store.state, 10603);
        if (label == 'CBExecute') return getLocale(this.$store.state, 10606);
        if (label == 'Complete') return getLocale(this.$store.state, 10607);
        if (label == 'LabelRecord') return getLocale(this.$store.state, 10420);
      }
      else if (this.route == 'finishDyeingCloth') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10016);
        if (label == 'LabelSelectFactory') return getLocale(this.$store.state, 11701);
        if (label == 'LabelSelectItem') return getLocale(this.$store.state, 11702);
        if (label == 'LabelFillInResult') return getLocale(this.$store.state, 11703);
        if (label == 'CBExecute') return getLocale(this.$store.state, 11706);
        if (label == 'Complete') return getLocale(this.$store.state, 11707);
        if (label == 'LabelRecord') return getLocale(this.$store.state, 10420);
      }
      else if (this.route == 'finishProductionProduct') {
        if (label == 'LabelTitle') return getLocale(this.$store.state, 10007);
        if (label == 'LabelSelectFactory') return getLocale(this.$store.state, 10701);
        if (label == 'LabelSelectItem') return getLocale(this.$store.state, 10702);
        if (label == 'LabelFillInResult') return getLocale(this.$store.state, 10703);
        if (label == 'CBExecute') return getLocale(this.$store.state, 10706);
        if (label == 'Complete') return getLocale(this.$store.state, 10707);
        if (label == 'LabelRecord') return getLocale(this.$store.state, 10420);
      }
    },

    //----------------------------------------------------------------------
    async testInput() {
      this.date = Util.formatDate(new Date());

      if (this.route == 'finishProductionCloth') {
        this.LBFactorySelected = this.LBFactory.find((item) => { return item.id == 3122; });
        await this.OnLBFactoryChanged();
      }
      else if (this.route == 'finishDyeingCloth') {
        this.LBFactorySelected = this.LBFactory.find((item) => { return item.id == 3219; });
        await this.OnLBFactoryChanged();
      }
      else if (this.route == 'finishProductionProduct') {
        this.LBFactorySelected = this.LBFactory.find((item) => { return item.id == 3214; });
        await this.OnLBFactoryChanged();
      }

      this.LBItemSelected = this.LBItem[0];
      this.TBAmount = Array(25).fill(0);
      this.TBAmount[0] = 1450;
      this.TBAmount[1] = 1550;
      this.TBCost = 100;

      await this.execute();
    },
  },
}

</script>